body{
  background-color: #494949;
}
@keyframes dropDown {
  0% {
    height: 0;
    opacity: 0;
  }
  
  100% {
    height: 100%;
    opacity: 1;
  }
  
}

.demo-wrap {
  overflow: hidden;
  position: relative;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.video-bg {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .project-container {
  color: black;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  width:72rem;
} */

.project-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}


.project-button:hover {
  background-color: #45a049; 
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.demo-content {
  position: relative;
}
.trans{
 
  animation-name: dropDown;
  animation-duration: 4s;
  animation-direction:normal;
}
.flipped {
  transform: rotateY(180deg);
  transition: transform 0.5s ease;
  
}

.skills-container {
  color: #1a1a1a;
  background-color: #E9E1D6;
  padding: 20px;
  padding-bottom: 5rem;
  border-radius: 8px; 
  width: 65%; 
  margin: auto; 
 
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.skills-section {
  background-color: white; 
  padding: 10px;
  border-radius: 8px; 
  
}

.skills-section h3 {
  margin: 0 0 10px 0;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill {

  padding: 5px 10px;
  border-radius: 4px;
  border-color: #1a1a1a;
  border-style: solid;
  border-width: 1px;
}

.skill:hover{
transform: scale(1.1);
}

.tt{
  
  transform: rotateY(180deg);
  
}
.unflipped {
  transform: rotateY(0deg);
  
  transition: transform 0.5s ease;
}
/* .hello{
  background-image: linear-gradient(green, yellow) !important;
  padding-top: 500px !important;
} */
@media screen and (max-width: 750px)
and (orientation: portrait) and (min-height: 350px){
  .skills-container{
    
    /* background-color: aqua; */
    padding-right:10px !important;
    padding-left: 10px !important;
    width: fit-content ;
    
  }
  .skills-container>div{
    padding: -10px;
    margin: 0px;
  }
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    gap: 5px;
    /* margin: 1px; */

  }
  .projects{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;

  }
  .project-container{
    width:calc(100% - 20px);
  }
  .project-headers{
   
    text-decoration: none !important;
    font-size: 20px !important;
    text-align: center !important;
    

  }
  .project-headers-container{
    width:fit-content !important;
  }

  .hello{
    
    padding-top: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: fit-content !important;
  }
  .Technology-big-container{
    height: fit-content !important;
    top: -50px !important;
    
  
  }
  .Technology-small-container{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    border: white 1px solid !important;
    
  }
  .Technology-small-container>div{
    width: 100% !important;
    height: fit-content !important;
  }
  .Technology-small-container {
    --tw-divide-x-reverse: 0;
    border-right-width: 0; /* Remove existing divider styles */
    border-left-width: 0;  /* This assumes divide-x affects these properties */
  }
  .Technology-small-container > * {
    border-right-width: 0 !important; /* Ensure it overrides Tailwind's styling */
    border-left-width:  0 !important;
    
  }
  .vid_cont{
    position: relative !important;
    left: 10px !important;
    min-width: 400px !important;
    
  }
  .video{
    width: 100% !important;
  
  }

  
}
@media screen and (max-width: 1000px) and (min-height: 751px){
  form{
    width: 100vh !important;
  }

  .big-box{
    height:60vh !important;
    width: 95vw !important;
  }
  form{
    /* dont show */
    height: 100vh !important;
    /* background-color: #45a049 !important; */
  }
  .Technology-small-container{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    border: white 2px solid !important;
  }
  .Technology-small-container>div{
    width: 100% !important;
    height: fit-content !important;
  }
  .Technology-small-container {
    --tw-divide-x-reverse: 0;
    border-right-width: 0; /* Remove existing divider styles */
    border-left-width: 0;  /* This assumes divide-x affects these properties */
   
  }
  .Technology-small-container > * {
    border-right-width: 0 !important; /* Ensure it overrides Tailwind's styling */
    border-left-width:  0 !important;
    position: relative !important;
    
  }
  .box1{
    border-bottom: white 1px solid !important;
  }
  .hello{
    padding: 20px !important;
    padding-top: 0px !important;

  }
  
  .skills-container{
    width: fit-content;
    margin: 30px;
    /* background-color: blue; */
  }  
  .projects{
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: fit-content;

  }
  .project-container{
    width: 4px / 5px;
  }
  .vid_cont{
    left: calc(-50vw + 250px) !important;
    width: calc(100vw - 300px) !important;
    
  }
}
@media screen and (max-width: 1350px) and (min-width: 1001px){
  .projects{
    display: flex;
    flex-direction: column !important;
    gap: 20px !important;
    height: fit-content !important;

  }
}
@keyframes form_animation {
  0% {
    height: 0;
    opacity: 0;
  }
  
  100% {
    height: 100%;
    opacity: 1;
  }
  
}
form{
  animation-name: form_animation;
  animation-duration: 4s;
  animation-direction:normal;
  
}